import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import FolderIcon from '@material-ui/icons/Folder';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import TodayIcon from '@material-ui/icons/Today';
import { Business, HelpOutline } from '@material-ui/icons';
import { NavLink, useLocation } from 'react-router-dom';

import * as statuses from '../../services/store/states';

import useOutsideClickAlert from '../library/hook/clickOutside';
import styles from './Navigation.module.css';
import Button from '../library/Button/Button';
import PremiumPopUp from '../PremiumPopUp';

export default function Navigation({
  userStatus, hasHolding, societyId, society, actions, isAuthorized, name,
  isActive,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscriptionOpen, setSubscriptionOpen] = useState(false);
  const [assemblyClass, setAssemblyClass] = useState(window.location.href.includes('assemblee') ? styles.selected : '');
  const navigationRef = useRef(null);

  const { t } = useTranslation();
  const location = useLocation();

  const goDashboard = () => {
    window.location.assign(`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}dashboard.ayomi.fr/?refresh_token=${window.localStorage.getItem('auth_refresh')}`);
  };

  useOutsideClickAlert(navigationRef, () => setIsOpen(false));

  useEffect(() => {
    if (userStatus === statuses.IDLE) {
      actions.fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    setAssemblyClass(location.pathname.includes('assemblee') ? styles.selected : '');
  }, [location]);

  const handleClickOnAssemblyFeature = (e) => {
    if (!society.assemblySubscription) {
      e.preventDefault();
      setSubscriptionOpen(true);
    }
  };

  const handleClickOnCapTableFeature = (e) => {
    if (!society.capitalTableSubscription) {
      e.preventDefault();
      setSubscriptionOpen(true);
    }
  };

  const close = () => setSubscriptionOpen(false);

  if (!societyId) {
    return (
      <div>
        <button
          className={styles.menuButton}
          type="button"
          aria-label="menuOpen"
          hidden={isOpen}
          onClick={() => setIsOpen(true)}
        >
          <MenuIcon
            className={styles.menuIcon}
          />
        </button>
        {
          isOpen && <div className={`fadeIn ${styles.overlay}`} />
        }
        <nav
          className={`${styles.navigation} ${(isOpen) ? styles.show : styles.hide}`}
          ref={navigationRef}
        >
          <div>
            <div
              className={styles.header}
            >
              <div className={styles.logoContainer}>
                <p className={styles.logo}>
                  {name.toUpperCase()}
                  <span>.</span>
                </p>
                <p className={styles.caption}>
                  Post-investissement
                </p>
              </div>
              <button
                className={styles.menuOpenButton}
                type="button"
                aria-label="menuClose"
                hidden={!isOpen}
                onClick={() => setIsOpen(false)}
              >
                <MenuOpenIcon
                  className={styles.MenuOpenIcon}
                />
              </button>
            </div>
          </div>
          <ul className={styles.list}>
            <button
              type="button"
              className={styles.logOut}
              onClick={actions.logOut}
            >
              <ExitToAppIcon className={styles.icon} />
              {t('navigation.logout')}
            </button>
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <div>
      {
        (isSubscriptionOpen)
        && (<PremiumPopUp close={close} society={society} />)
      }
      <button
        className={styles.menuButton}
        type="button"
        aria-label="menuOpen"
        hidden={isOpen}
        onClick={() => setIsOpen(true)}
      >
        <MenuIcon
          className={styles.menuIcon}
        />
      </button>
      {
        isOpen && <div className={`fadeIn ${styles.overlay}`} />
      }
      <nav
        className={`${styles.navigation} ${(isOpen) ? styles.show : styles.hide}`}
        ref={navigationRef}
      >
        <div>
          <div
            className={styles.header}
          >
            <div className={styles.logoContainer}>
              <p className={styles.logo}>
                {name.toUpperCase()}
                <span>.</span>
              </p>
              <p className={styles.caption}>
                Post-investissement
              </p>
            </div>
            <button
              className={styles.menuOpenButton}
              type="button"
              aria-label="menuClose"
              hidden={!isOpen}
              onClick={() => setIsOpen(false)}
            >
              <MenuOpenIcon
                className={styles.MenuOpenIcon}
              />
            </button>
          </div>
          <ul
            className={styles.list}
          >
            <li>
              <NavLink
                to={`/${societyId}/`}
                activeClassName={styles.selected}
                className={styles.link}
                exact
              >
                <div
                  className={styles.linkContent}
                >
                  <HomeIcon
                    className={styles.icon}
                  />
                  {t('navigation.home')}
                </div>
                <ChevronRightIcon
                  className={styles.arrowIcon}
                />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${societyId}/investments`}
                activeClassName={styles.selected}
                className={`${styles.link} userInvestment`}
                exact
              >
                <div
                  className={styles.linkContent}
                >
                  <FolderIcon className={styles.icon} />
                  {(isAuthorized) ? t('navigation.investUser') : t('navigation.invest')}
                </div>
                <ChevronRightIcon
                  className={styles.arrowIcon}
                />
              </NavLink>
            </li>
            {
              ((society.isOwner === true) || society.captitalTableSubscription)
              && (
                <li className="captable">
                  <NavLink
                    to={`/${societyId}/actionnaires`}
                    activeClassName={styles.selected}
                    className={`${styles.link} captable`}
                    onClick={(e) => {
                      handleClickOnCapTableFeature(e);
                      if (isActive) actions.handleNext({ lifecycle: 'complete' });
                    }}
                  >
                    <div
                      className={styles.linkContent}
                    >
                      <PersonIcon className={styles.icon} />
                      {t('navigation.shareholders')}
                    </div>
                    <ChevronRightIcon
                      className={styles.arrowIcon}
                    />
                  </NavLink>
                </li>
              )
            }
            {
              (hasHolding)
              && ((society.isOwner === true) || society.capitalTableSubscription)
              && (
                <li className="captable">
                  <NavLink
                    to={`/${societyId}/holding`}
                    activeClassName={styles.selected}
                    className={`${styles.link}`}
                    onClick={handleClickOnCapTableFeature}
                  >
                    <div
                      className={styles.linkContent}
                    >
                      <Business className={styles.icon} />
                      {t('navigation.holding')}
                    </div>
                    <ChevronRightIcon
                      className={styles.arrowIcon}
                    />
                  </NavLink>
                </li>
              )
            }

            {
              ((society.isOwner === true) || society.capitalTableSubscription)
              && (
                <li>
                  <NavLink
                    to={`/${societyId}/rapport`}
                    activeClassName={styles.selected}
                    className={`${styles.link} report`}
                    onClick={handleClickOnCapTableFeature}
                  >
                    <div
                      className={styles.linkContent}
                    >
                      <NotificationsIcon className={styles.icon} />
                      {t('navigation.report')}
                    </div>
                    <ChevronRightIcon
                      className={styles.arrowIcon}
                    />
                  </NavLink>
                </li>
              )
            }
            {
              ((society.isOwner === true) || society.assemblySubscription)
              && (
                <li>
                  <NavLink
                    to={`/${societyId}/assemblee/registre`}
                    activeClassName={styles.selected}
                    className={`${styles.link} ${assemblyClass} assembly`}
                    onClick={handleClickOnAssemblyFeature}
                  >
                    <div
                      className={styles.linkContent}
                    >
                      <TodayIcon className={styles.icon} />
                      {t('navigation.assembly')}
                    </div>
                    <ChevronRightIcon
                      className={styles.arrowIcon}
                    />
                  </NavLink>
                </li>
              )
            }
          </ul>
        </div>
        <ul className={styles.list}>
          <li>
            <NavLink
              to="/documentation"
              activeClassName={styles.selected}
              className={`${styles.link} documentation`}
            >
              <div
                className={styles.linkContent}
              >
                <HelpOutline className={styles.icon} />
                {t('navigation.documentation')}
              </div>
              <ChevronRightIcon
                className={styles.arrowIcon}
              />
            </NavLink>
          </li>
          {
            (isAuthorized)
            && (
              <li>
                <NavLink
                  to={`/${societyId}/settings`}
                  activeClassName={styles.selected}
                  className={styles.link}
                >
                  <div
                    className={styles.linkContent}
                  >
                    <SettingsIcon className={styles.icon} />
                    {t('navigation.settings')}
                  </div>
                  <ChevronRightIcon
                    className={styles.arrowIcon}
                  />
                </NavLink>
              </li>
            )
          }
          <li>
            <Button
              label={t('navigation.mainDashboard')}
              className={styles.dashboard}
              onButtonClick={goDashboard}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
}
