import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Close } from '@material-ui/icons';

import styles from './PremiumPopUp.module.css';
import Button from '../library/Button/Button';

export default function PremiumPopUp({ society, close }) {
  const { t } = useTranslation();
  const handleRedirection = () => window.open(`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}dashboard.ayomi.fr/billing`);
  return (
    <div className={styles.background}>
      <div className={styles.modal}>
        <button
          type="button"
          aria-label="close"
          onClick={close}
          className={styles.close}
        >
          <Close />
        </button>
        <p className={styles.title}>
          {t('premium.subscription.title')}
        </p>
        <p className={styles.description}>
          {t('premium.subscription.description')}
        </p>
        <div className={styles.row}>
          <div className={styles.offer}>
            <p className={styles.title}>
              {t('premium.subscription.starter.title')}
              <span className={styles.own}>
                {t('premium.subscription.starter.current')}
              </span>
            </p>
            <p className={styles.description}>
              {t('premium.subscription.starter.description')}
            </p>
            <hr className={styles.separator} />
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.starter.fundraising')}
              </span>
            </p>
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.starter.documents')}
              </span>
            </p>
          </div>
          <div className={styles.offer}>
            <p className={styles.title}>
              {t('premium.subscription.registering.title')}
              {
                society.capitalTableSubscription
                && (
                  <span className={styles.own}>
                    {t('premium.subscription.starter.current')}
                  </span>
                )
              }
            </p>
            <p className={styles.description}>
              {t('premium.subscription.registering.description')}
            </p>
            <hr className={styles.separator} />
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.registering.digital')}
              </span>
            </p>
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.registering.captable')}
              </span>
            </p>
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.registering.reporting')}
              </span>
            </p>
            <Button
              label={t('premium.subscription.ag.more')}
              variant="ghost"
              className={styles.more}
              onButtonClick={handleRedirection}
            />
            {
              (!society.capitalTableSubscription)
              && (
                <Button
                  label={t('premium.subscription.add')}
                  className={styles.subscribe}
                  onButtonClick={handleRedirection}
                />
              )
            }
          </div>
          <div className={styles.offer}>
            <p className={styles.title}>
              {t('premium.subscription.ag.title')}
              {
                society.assemblySubscription
                && (
                  <span className={styles.own}>
                    {t('premium.subscription.starter.current')}
                  </span>
                )
              }
            </p>
            <p className={styles.description}>
              {t('premium.subscription.ag.description')}
            </p>
            <hr className={styles.separator} />
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.ag.document')}
              </span>
            </p>
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.ag.preparation')}
              </span>
            </p>
            <p className={styles.benefit}>
              <Check className={styles.icon} />
              <span>
                {t('premium.subscription.ag.convocation')}
              </span>
            </p>
            <Button
              label={t('premium.subscription.ag.more')}
              variant="ghost"
              className={styles.more}
              onButtonClick={handleRedirection}
            />
            {
              (!society.assemblySubscription)
              && (
                <Button
                  label={t('premium.subscription.add')}
                  className={styles.subscribe}
                  onButtonClick={handleRedirection}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
