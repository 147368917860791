import React, { useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './PopUp.module.css';
import useOutsideClickAlert from '../library/hook/clickOutside';

export default function PopUp({
  isOpen, title, description, children, actions,
}) {
  const popupRef = useRef(null);
  useOutsideClickAlert(popupRef, () => actions.closePopUp());
  if (!isOpen) return null;
  return (
    <section
      className={styles.popup}
    >
      <div
        className={styles.main}
        ref={popupRef}
      >
        {
          (title && children)
          && (
            <header
              className={styles.header}
            >
              <div className={styles.description}>
                <h2>
                  {title}
                </h2>
                <p>
                  {description}
                </p>
              </div>
              <button
                type="button"
                aria-label="close"
                onClick={actions.closePopUp}
              >
                <CloseIcon />
              </button>
            </header>
          )
        }
        {children}
      </div>
      <div className={styles.overlay} />
    </section>
  );
}
