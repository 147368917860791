import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import * as statuses from 'services/store/states';
import Checkbox from 'components/library/Input/Checkbox/Checkbox';
import InputText from 'components/library/Input/InputText/InputText';
import Button from 'components/library/Button/Button';
import ShareholdersHeaderSearchbar from 'components/Shareholders/ShareholdersHeaderSearchbar';

import styles from './EventsPopUp.module.css';

export default function EventsPopUpInvitation({
  statusShareholders, shareholders, event, back, status, handleUpdate, submit, actions,
}) {
  const [inviteAll, setInviteAll] = useState(false);
  const [mail, setMail] = useState('');
  const [mails, setMails] = useState([]);
  const [shareholdersMails, setShareholdersMail] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (statusShareholders === statuses.IDLE) {
      actions.fetchAllShareholders();
    }
    if (statusShareholders === statuses.SUCCESS) {
      if (event) {
        const shareholdersMailList = shareholders.map((i) => i.email);
        setMails(event.mails.filter(
          (currentMail) => !shareholdersMailList.include(currentMail),
        ));
        setShareholdersMail(event.mails.filter(
          (currentMail) => shareholdersMailList.include(currentMail),
        ));
      }
    }
  }, [statusShareholders]);

  useEffect(() => {
    if (shareholders.length > 0) {
      if (event && event.mails) {
        const mailsList = event.mails.split(';');
        const shareholdersMailList = shareholders.map((i) => i.email);
        setMails(mailsList.filter(
          (currentMail) => !shareholdersMailList.includes(currentMail),
        ));
        setShareholdersMail(mailsList.filter(
          (currentMail) => shareholdersMailList.includes(currentMail),
        ));
      }
    }
  }, [event]);

  const removeMail = (mailInput) => {
    setMails((list) => list.filter((item) => item !== mailInput));
  };

  const addMail = () => {
    if (mail.trim() !== '') {
      setMails((list) => [...list, ...mail.split(';')]);
      setMail('');
    }
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      addMail();
    }
  };

  const handleInviteAll = (bool) => {
    setInviteAll(bool);
    if (bool) {
      setShareholdersMail(shareholders.map((i) => i.email));
    } else {
      setShareholdersMail([]);
    }
  };

  const handleShareholderChange = (bool, mailInput) => {
    if (bool) {
      setShareholdersMail((list) => [...list, mailInput]);
    } else {
      setShareholdersMail((list) => list.filter((item) => item !== mailInput));
    }
  };

  return (
    <section className={styles.event}>
      <main className={styles.content}>
        <h3>
          Inviter
        </h3>
        <Checkbox
          label={t('events.form.inviteAll')}
          checked={inviteAll}
          onChange={handleInviteAll}
        />
        {
          (!inviteAll)
          && (
            <ul className={styles.list}>
              <ShareholdersHeaderSearchbar />
              {
                shareholders.map((shareholder) => (
                  <div
                    key={shareholder.id}
                    className={styles.shareholder}
                  >
                    <div className={styles.profile}>
                      {
                        (shareholder.img)
                          ? (
                            <img
                              alt="profil"
                              src={shareholder.img}
                            />
                          )
                          : (
                            <div className={styles.shareholderLogo}>
                              {shareholder.firstName[0]}
                            </div>
                          )
                      }
                      <p>
                        {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.mail}
                      </p>
                    </div>
                    <Checkbox
                      checked={shareholdersMails.includes(shareholder.email)}
                      onChange={(bool) => handleShareholderChange(bool, shareholder.email)}
                    />
                  </div>
                ))
              }
            </ul>
          )
        }
        <p>
          {t('events.form.inviteDescription')}
        </p>
        <InputText
          label={t('events.form.mail')}
          valueInput={mail}
          onTextChange={(e) => setMail(e.target.value)}
          onKeyDown={onEnter}
          onBlur={addMail}
        />
        <ul className={styles.mailList}>
          {mails.map((item) => (
            <li key={item}>
              {item}
              <button
                type="button"
                aria-label="close"
                className={styles.mail}
                onClick={() => removeMail(item)}
              >
                <CloseIcon />
              </button>
            </li>
          ))}
        </ul>
      </main>
      <footer
        className={styles.footer}
      >
        <Button
          type="custom"
          label={t('cancel')}
          className={styles.tertiaryButton}
          onButtonClick={actions.closePopUp}
        />
        <Button
          type="custom"
          label={t('events.form.back')}
          className={styles.tertiaryButton}
          onButtonClick={back}
        />
        {
          (event && event.id)
            ? (
              <Button
                type="secondary"
                label={(status === statuses.LOADING) ? t('events.edit.loading') : t('events.edit.title')}
                icon={
                  (status === statuses.LOADING)
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : null
                }
                onButtonClick={() => handleUpdate(shareholdersMails, mails)}
              />
            )
            : (
              <Button
                type="secondary"
                label={(status === statuses.LOADING) ? t('events.add.update') : t('events.add.button')}
                icon={
                  (status === statuses.LOADING)
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : null
                }
                onButtonClick={() => submit(shareholdersMails, mails)}
              />
            )
        }
      </footer>
    </section>
  );
}
