import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'components/library/Button/Button';
import Checkbox from 'components/library/Input/Checkbox/Checkbox';
import InputText from 'components/library/Input/InputText/InputText';
import ShareholdersHeaderSearchbar from 'components/Shareholders/ShareholdersHeaderSearchbar';
import useOutsideClick from 'components/library/hook/clickOutside';
import ImgPlaceholder from 'components/ImgPlaceholder';

import styles from 'components/Events/EventsPopUp/EventsPopUp.module.css';
import shared from './AssemblySummons.module.css';

const getNonShareholdersMail = (invitations, shareholders) => {
  if (!invitations) return [];
  const mailToExclude = shareholders.map((i) => i.email);
  return invitations
    .map((invite) => ({ email: invite.email, shareholderId: null }))
    .filter(({ email }) => !mailToExclude.includes(email));
};

const getShareholdersMail = (invitations, shareholders) => {
  if (!invitations) return [];
  const mailToInclude = shareholders.map((i) => i.email);
  return invitations.map((invite) => ({
    email: invite.email, shareholderId: invite.shareholderId,
  })).filter(({ email }) => mailToInclude.includes(email));
};

export default function AssemblySummonsInvitation({
  shareholders, callback, invitations, close,
}) {
  const [inviteAll, setInviteAll] = useState(false);
  const [mail, setMail] = useState('');
  const [mails, setMails] = useState(getNonShareholdersMail(invitations, shareholders));
  const [shareholdersMails, setShareholdersMail] = useState(
    getShareholdersMail(invitations, shareholders),
  );

  const popupRef = useRef();
  const { t } = useTranslation();

  useOutsideClick(popupRef, close);

  const removeMail = ({ email: mailInput }) => {
    setMails((list) => list.filter(({ email }) => email !== mailInput));
  };

  const addMail = () => {
    if (mail.trim() !== '') {
      setMails((list) => [
        ...list,
        ...mail
          .split(';')
          .map((curr) => ({ email: curr, shareholderId: null })),
      ]);
      setMail('');
    }
  };

  const handleInviteAll = (bool) => {
    setInviteAll(bool);
    if (bool) {
      setShareholdersMail(shareholders
        .map((i) => ({ email: i.email, shareholderId: i.shareholderId })));
    } else {
      setShareholdersMail([]);
    }
  };

  const handleShareholderChange = (bool, mailInput) => {
    if (bool) {
      setShareholdersMail((list) => [...list, mailInput]);
    } else {
      setShareholdersMail((list) => list.filter(({ email }) => email !== mailInput.email));
    }
  };

  const submit = () => {
    callback([...shareholdersMails, ...mails], inviteAll);
    close();
  };

  return (
    <div className={shared.popUpContainer}>
      <section
        ref={popupRef}
        className={shared.popUp}
      >
        <h1>
          Gérer vos invitations
        </h1>
        <main>
          <Checkbox
            label={t('events.form.inviteAll')}
            checked={inviteAll}
            onChange={handleInviteAll}
          />
          {
            (!inviteAll)
            && (
              <ul className={styles.list}>
                <ShareholdersHeaderSearchbar />
                {
                  shareholders.map((shareholder) => (
                    <div
                      key={shareholder.id}
                      className={styles.shareholder}
                    >
                      <div className={styles.profile}>
                        {
                          (shareholder.img)
                            ? (
                              <ImgPlaceholder
                                alt="profil"
                                src={shareholder.img}
                              />
                            )
                            : (
                              <div className={styles.shareholderLogo}>
                                {
                                  (shareholder.firstName)
                                    ? shareholder.firstName[0]
                                    : shareholder.email[0]
                                }
                              </div>
                            )
                        }
                        <p>
                          {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.email}
                        </p>
                      </div>
                      <Checkbox
                        checked={shareholdersMails
                          .map((curr) => curr?.email)
                          .includes(shareholder?.email)}
                        onChange={(bool) => handleShareholderChange(
                          bool,
                          { email: shareholder.email, shareholderId: shareholder.id },
                        )}
                      />
                    </div>
                  ))
                }
              </ul>
            )
          }
          <p>
            {t('events.form.inviteDescription')}
          </p>
          <InputText
            label={t('events.form.mail')}
            valueInput={mail}
            onTextChange={(e) => setMail(e.target.value)}
            onBlur={addMail}
          />
          <ul className={styles.mailList}>
            {mails.map((item) => (
              <li key={item}>
                {item.email}
                <button
                  type="button"
                  aria-label="close"
                  className={styles.mail}
                  onClick={() => removeMail(item)}
                >
                  <CloseIcon />
                </button>
              </li>
            ))}
          </ul>
        </main>
        <Button
          label={t('assembly.summons.guest.validate')}
          onButtonClick={submit}
        />
      </section>
    </div>
  );
}
