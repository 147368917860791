import React, { useMemo, useState } from 'react';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from 'components/library/Button/Button';
import InputText from 'components/library/Input/InputText/InputText';
import { createShareholder, createShareholdersSociety } from 'services/clients/shareholders';
import { isEmailValid } from '../../../../../utils/utils';

import styles from './SettingsInvestorsCreationBulk.module.css';
import { legalShareholderToApi, shareholderToApi } from '../../../../../services/store/features/shareholders/adapter';

const INVESTOR = {
  email: '', firstName: '', lastName: '', siren: '',
};

export default function SettingsInvestorsCreationBulk({ close, actions }) {
  const [investors, setInvestors] = useState([{
    ...INVESTOR, id: 1,
  }]);

  const { society: societyId } = useParams();

  const isSubmittable = (investor) => !!(isEmailValid(investor.email)
    && investor.firstName && investor.lastName);

  const isDisabled = useMemo(
    () => investors.filter((investor) => isSubmittable(investor)).length === 0,
    [investors],
  );
  const { t } = useTranslation();

  const onChange = (e) => {
    const { id } = (e.currentTarget || e.target).dataset;
    setInvestors((curr) => (
      curr.map((currInvestor) => (
        (currInvestor.id?.toString() === id)
          ? { ...currInvestor, [e.target.name]: e.target.value }
          : currInvestor))
    ));
  };

  const onDelete = (e) => {
    const { id: idToDelete } = (e.currentTarget || e.target).dataset;
    setInvestors((curr) => curr.filter(({ id }) => id.toString() !== idToDelete));
  };

  const addInvestor = () => {
    setInvestors((curr) => [
      ...curr,
      {
        ...INVESTOR,
        id: (curr.length === 0)
          ? 1
          : (Math.max(...curr.map(({ id }) => id)) || 1) + 1,
      },
    ]);
  };

  const sendInvitation = async () => {
    const investorToSubmit = investors.filter((investor) => isSubmittable(investor));
    try {
      await Promise.all(investorToSubmit.map((investor) => {
        if (investor.siren) {
          return createShareholdersSociety(legalShareholderToApi({
            email: investor.email,
            firstName: investor.firstName,
            lastName: investor.lastName,
            siren: investor.siren,
            societyId,
          }));
        }
        return createShareholder(shareholderToApi({
          email: investor.email,
          firstName: investor.firstName,
          lastName: investor.lastName,
          societyId,
        }));
      }));
      actions.fetchAllShareholders();
      close();
    } catch (err) {
      actions.addToast({
        title: t('settings.investors.bulk.error'),
        description: `${err}`,
      });
    }
  };

  return (
    <div>
      <p className={styles.title}>
        {t('settings.investors.bulk.title')}
      </p>
      <p className={styles.description}>
        {t('settings.investors.bulk.description')}
      </p>
      {
        (investors.map((investor, key) => (
          <div
            className={styles.row}
            key={investor.id}
          >
            <p className={styles.number}>
              {key + 1}
            </p>
            <InputText
              placeholder={t('settings.investors.bulk.email')}
              name="email"
              id={investor.id}
              onTextChange={onChange}
              valueInput={investor.email}
            />
            <InputText
              placeholder={t('settings.investors.bulk.firstname')}
              valueInput={investor.firstName}
              name="firstName"
              id={investor.id}
              onTextChange={onChange}
            />
            <InputText
              placeholder={t('settings.investors.bulk.lastname')}
              valueInput={investor.lastName}
              name="lastName"
              id={investor.id}
              onTextChange={onChange}
            />
            <InputText
              placeholder={t('settings.investors.bulk.siren')}
              valueInput={investor.siren}
              name="siren"
              id={investor.id}
              onTextChange={onChange}
            />
            <button
              type="button"
              aria-label="delete"
              className={styles.delete}
              data-id={investor.id}
              onClick={onDelete}
            >
              <Delete className={styles.icon} />
            </button>
          </div>
        )))
      }
      <Button
        label={t('settings.investors.bulk.add-investors')}
        variant="ghost"
        onButtonClick={addInvestor}
      />
      <Button
        label={t('settings.investors.bulk.send-invitation')}
        onButtonClick={sendInvitation}
        className={styles.send}
        disabled={isDisabled}
      />
    </div>
  );
}
