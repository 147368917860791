import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';
import { CheckCircle } from '@material-ui/icons';

import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import Button from '../../../components/library/Button/Button';
import Checkbox from '../../../components/library/Input/Checkbox/Checkbox';
import AccountLoading from '../../../components/library/Loading';
import * as statuses from '../../../services/store/states';
import { keysToCamel } from '../../../utils/converter';

import styles from './AssemblySummons.module.css';
import shared from '../shared/assembly.module.css';
import {
  addDocumentToAssembly,
  getPlannedAssemblyDocument,
  sendAssemblyInvitations,
} from '../../../services/clients/assembly';

export default function AssemblySummonsSend({
  assembly, actions, next, back, societyId, roles,
}) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [documentsState, setDocumentsState] = useState(statuses.IDLE);
  const [uploadState, setUploadState] = useState(statuses.IDLE);

  const [documents, setDocuments] = useState([]);
  const [isDocumentOnline, setIsDocumentOnline] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchDocuments = async () => {
      if (documentsState === statuses.IDLE) {
        setDocumentsState(statuses.LOADING);
        try {
          const { data } = await getPlannedAssemblyDocument(assembly.id);
          const formattedDocument = keysToCamel(data);
          setDocuments(formattedDocument);
          setSelectedDocument(formattedDocument.map((doc) => doc.id));
          setDocumentsState(statuses.SUCCESS);
        } catch (err) {
          setDocumentsState(statuses.FAIL);
        }
      }
    };
    fetchDocuments();
  }, []);

  const handleDocumentUpload = async (e) => {
    setUploadState(statuses.LOADING);
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', file.name);
      formData.append('society_id', societyId);
      const { data } = await addDocumentToAssembly(assembly.id, formData);
      setDocuments((state) => [...state, { title: file.name, ...data }]);
      setUploadState(statuses.SUCCESS);
      setSelectedDocument((state) => [...state, data.id]);
    } catch (err) {
      setUploadState(statuses.FAIL);
    } finally {
      window.setTimeout(() => setUploadState(statuses.IDLE), 2000);
    }
  };

  const submit = async () => {
    setStatus(statuses.LOADING);
    try {
      const { data } = await sendAssemblyInvitations(assembly.id);
      if (data?.status) {
        setStatus(statuses.SUCCESS);
        const newAssembly = {
          ...assembly,
          convocation: {
            ...assembly?.convocation,
            isDocumentOnline,
            documents: selectedDocument,
          },
        };

        actions.updateAssemblyPlanById(newAssembly.id, newAssembly);
        next();
      } else {
        setStatus(statuses.FAIL);
        actions.addToast({
          type: 'error',
          title: t('assembly.summons.sendError'),
          description: t('assembly.summons.sendErrorDescription'),
        });
      }
    } catch (err) {
      setStatus(statuses.FAIL);
      actions.addToast({
        type: 'error',
        title: t('assembly.summons.sendError'),
        description: `${err}`,
      });
    }
  };

  const inputRef = useRef();

  return (
    <section className={styles.summons}>
      <AssemblyHeader
        societyId={societyId}
        roles={roles}
      />
      <section className={shared.section}>
        <h2 className={styles.title}>
          {assembly?.title}
        </h2>
        <div className={styles.content}>
          <header className={styles.spacedRow}>
            <h3 className={styles.subTitle}>
              {t('assembly.summons.send')}
            </h3>
          </header>
          {
            (status === statuses.LOADING)
              ? (<AccountLoading />)
              : (
                <>
                  <main className={`${styles.main}`}>
                    <div className={styles.warning}>
                      <p className={styles.icon}>
                        <WarningIcon />
                      </p>
                      <p className={styles.description}>
                        {t('assembly.summons.sendHint')}
                      </p>
                    </div>
                    <p className={styles.bold}>Document</p>
                    {
                      (documentsState === statuses.LOADING)
                      && <AccountLoading />
                    }
                    {
                      (
                        (documentsState === statuses.SUCCESS)
                        && documents
                        && documents.length > 0
                      )
                      && (
                        documents.map((doc) => (
                          <div
                            className={styles.documentsButton}
                            key={doc.id}
                          >
                            <p>
                              {doc.title}
                            </p>
                            <div>
                              {
                                (selectedDocument.includes(doc.id))
                                  ? (
                                    <button
                                      type="button"
                                      aria-label="select"
                                      onClick={() => {
                                        setSelectedDocument((state) => state
                                          .filter((i) => i !== doc.id));
                                      }}
                                      className={styles.selected}
                                    >
                                      <CheckCircle />
                                    </button>
                                  )
                                  : (
                                    <button
                                      type="button"
                                      aria-label="unselect"
                                      onClick={() => {
                                        setSelectedDocument((state) => [...state, doc.id]);
                                      }}
                                      className={styles.unselected}
                                    >
                                      <CheckCircle />
                                    </button>
                                  )
                              }
                            </div>
                          </div>
                        ))
                      )
                    }
                    <input
                      type="file"
                      className={styles.hidden}
                      ref={inputRef}
                      onChange={handleDocumentUpload}
                    />
                    <Button
                      label={
                        (uploadState !== statuses.LOADING)
                          ? t('assembly.summons.document')
                          : t('assembly.summons.documentUpload')
                      }
                      onButtonClick={() => {
                        inputRef.current.click();
                      }}
                      disabled={
                        (documentsState !== statuses.SUCCESS)
                        || (uploadState === statuses.LOADING)
                      }
                    />
                    <p className={styles.bold}>
                      {t('assembly.summons.documentMethod')}
                    </p>
                    <Checkbox
                      label={t('assembly.summons.shareholderSpace')}
                      className={styles.checkbox}
                      checked={isDocumentOnline}
                      onChange={(bool) => setIsDocumentOnline(bool)}
                    />
                    <Checkbox
                      label={t('assembly.summons.documentJoined')}
                      className={styles.checkbox}
                      checked={!isDocumentOnline}
                      onChange={(bool) => setIsDocumentOnline(!bool)}
                    />
                  </main>
                  <div className={styles.submit}>
                    <button
                      type="button"
                      className={styles.outline}
                      onClick={back}
                    >
                      {t('assembly.summons.back')}
                    </button>
                    <Button
                      label={t('assembly.summons.send')}
                      onButtonClick={submit}
                      disabled={!assembly}
                    />
                  </div>
                </>
              )
          }
        </div>
      </section>
    </section>
  );
}
