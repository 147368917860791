import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';

import InputText from '../../../components/library/Input/InputText/InputText';
import Checkbox from '../../../components/library/Input/Checkbox/Checkbox';
import Button from '../../../components/library/Button/Button';
import * as statuses from '../../../services/store/states';

import styles from './AssemblyReport.module.css';

export default function AssemblyReportCreate({
  actions, presence, next, vote, societyId,
}) {
  const [report, setReport] = useState('');
  const [isImported, setIsImported] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [errors, setErrors] = useState({});

  const inputFile = useRef();
  const { id } = useParams();
  const { t } = useTranslation();

  const handleFile = (e) => {
    const index = (documents.length === 0)
      ? 0
      : (Math.max(...documents.map((doc) => doc.id)) + 1);
    setDocuments((state) => [...state, { id: index, file: e.target.files[0] }]);
  };

  const submit = () => {
    const err = {};
    if (!report) {
      err.report = t('assembly.minutes.error.title');
    }
    if (isImported && documents.length === 0) {
      err.documents = t('assembly.minutes.error.file');
    }
    setErrors(err);
    if (Object.keys(err).length === 0) {
      if (isImported) {
        actions.uploadReportById(id, { title: report, documents });
      } else {
        next({ title: report });
      }
    }
  };

  return (
    <main className={styles.content}>
      <h3 className={styles.title}>
        {t('assembly.minutes.add')}
      </h3>
      {
        (errors.documents)
        && (
          <div className={`bounceIn ${styles.errorHandler}`}>
            <WarningIcon className={styles.icon} />
            <p>
              {errors.documents}
            </p>
          </div>
        )
      }
      <form className={styles.form}>
        <InputText
          label={t('assembly.minutes.name')}
          valueInput={report}
          onTextChange={(e) => setReport(e.target.value)}
          state={errors.report && statuses.FAIL}
          hint={errors.report}
          required
        />
        <div className={styles.choice}>
          <p className={styles.label}>
            {t('assembly.minutes.what')}
          </p>
          <Checkbox
            label={t('assembly.minutes.import')}
            checked={isImported}
            onChange={(bool) => setIsImported(bool)}
          />
          <Checkbox
            label={t('assembly.minutes.write')}
            checked={!isImported}
            onChange={(bool) => setIsImported(!bool)}
            className={styles.checkbox}
          />
        </div>
        {
          (isImported)
          && (
            <div>
              <p className={styles.label}>
                Documents
              </p>
              <input
                type="file"
                onChange={handleFile}
                ref={inputFile}
                hidden
              />
              <Button
                label={t('assembly.minutes.document')}
                onButtonClick={() => inputFile.current.click()}
                size="small"
              />
              {
                (documents.length > 0)
                && (
                  <ul>
                    {
                      documents.map((doc) => (
                        <li key={doc.id}>
                          <p>
                            {doc.file.name}
                          </p>
                          <button
                            type="button"
                            aria-label="close"
                            onClick={() => setDocuments((state) => state
                              .filter((i) => i.id !== doc.id))}
                          >
                            <CloseIcon />
                          </button>
                        </li>
                      ))
                    }
                  </ul>
                )
              }
            </div>
          )
        }
        <p className={styles.presence}>
          {t('assembly.minutes.attendance')}
        </p>
        {
          (presence)
            ? (
              <p className={styles.status}>
                {`${t('assembly.minutes.status')} :`}
                <span className={styles.complete}>
                  <CheckCircleIcon className={styles.icon} />
                  {t('assembly.minutes.completed')}
                </span>
              </p>
            )
            : (
              <div
                className={styles.presenceContainer}
              >
                <p className={styles.status}>
                  {`${t('assembly.minutes.status')} :`}
                  <span className={styles.uncomplete}>
                    <CancelIcon
                      className={styles.icon}
                    />
                    {`${t('assembly.minutes.toComplete')} :`}
                  </span>
                </p>
                <Link
                  to={`/${societyId}/assemblee/presence/${id}`}
                  className={`button ${styles.button}`}
                >
                  {t('assembly.minutes.completeButton')}
                </Link>
              </div>
            )
        }
        <p className={styles.presence}>
          {t('assembly.minutes.vote.title')}
        </p>
        {
          (presence && vote)
            ? (
              <p className={styles.status}>
                {`${t('assembly.minutes.status')} :`}
                <span className={styles.complete}>
                  <CheckCircleIcon className={styles.icon} />
                  {t('assembly.minutes.completed')}
                </span>
              </p>
            )
            : (
              <div
                className={styles.presenceContainer}
              >
                <p className={styles.status}>
                  {`${t('assembly.minutes.status')} :`}
                  <span className={styles.uncomplete}>
                    <CancelIcon
                      className={styles.icon}
                    />
                    {`${t('assembly.minutes.toComplete')} :`}
                  </span>
                </p>
                <Button
                  label={t('assembly.minutes.vote.askAgain')}
                  onButtonClick={actions.voteReminder}
                />
              </div>
            )
        }
      </form>
      <div className={styles.buttonGroup}>
        <Button
          label={(isImported) ? t('assembly.minutes.save') : t('assembly.minutes.next')}
          onButtonClick={submit}
        />
      </div>
    </main>
  );
}
