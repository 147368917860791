import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';

import Button from '../../../components/library/Button/Button';
import InputText from '../../../components/library/Input/InputText/InputText';
import * as statuses from '../../../services/store/states';
import useForm from '../../../hook/useForm';

import validate from './formValidation';
import styles from './OperationModifyTransfert.module.css';

export default function OperationModifyTransfertForm({
  operation, shareholders, history, submit, hasSubmitted, openForm, deleteOperation,
}) {
  const { t } = useTranslation();

  const handleValidation = (values) => {
    if (!values.from) {
      return validate(values, 0, t);
    }
    const fromShareholder = shareholders
      .filter((shareholder) => shareholder.id === Number.parseInt(values.from, 10))[0];
    if (!fromShareholder) {
      return validate(values, 0, t);
    }
    if (operation.id) {
      const operationId = values.name;
      const historyId = history.history.find((h) => h.name === operationId)?.id;
      const index = history.history.map((i) => i.id).indexOf(historyId) - 1;
      const historyToCheck = (index !== -1) ? history.history[index]?.id : history.history[0].id;
      const shares = (historyToCheck)
        ? (fromShareholder.history.find((h) => h.historyId === historyToCheck)?.total)
        : fromShareholder.shares;
      return validate(values, shares, t);
    }
    return validate(values, fromShareholder.shares, t);
  };

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(
    submit,
    handleValidation,
    {
      ...operation,
      from: operation.from?.shareholderId,
      to: operation.to?.shareholderId,
    },
  );

  useEffect(() => {
    if (hasSubmitted) {
      handleSubmit();
    }
  }, [hasSubmitted]);

  return (
    <div key={operation.id} className={styles.row}>
      <div className={styles.dropdown}>
        <p className={styles.label}>
          {t('operation.between')}
        </p>
        <select
          name="from"
          value={values.from}
          onChange={(e) => {
            handleChange(e);
          }}
          className={(errors.from) && styles.SelectError}
          defaultValue={-1}
        >
          <option value={-1} disabled>
            {t('operation.shareholderChoice')}
          </option>
          {
            (operation.id && !operation.isNew)
              ? (
                shareholders
                  .filter((shareholder) => history.history
                    .filter((h) => h.name === operation.name)[0]
                    ?.shareholders
                    .includes(shareholder.id))
                  .map((shareholder) => (
                    <option
                      key={shareholder.id}
                      value={shareholder.id}
                    >
                      {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.mail}
                    </option>
                  ))
              )
              : (
                shareholders
                  .filter((shareholder) => history.history[0]?.shareholders
                    .includes(shareholder.id))
                  .map((shareholder) => (
                    <option
                      key={shareholder.id}
                      value={shareholder.id}
                    >
                      {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.mail}
                    </option>
                  ))
              )
          }
        </select>
        {
          (errors.from)
          && (
            <p className={`${styles.label} ${styles.error}`}>
              {errors.from}
            </p>
          )
        }
      </div>
      <div className={styles.dropdown}>
        <p className={styles.label}>
          {t('operation.and')}
        </p>
        <select
          name="to"
          value={values.to}
          onChange={handleChange}
          className={(errors.to) && styles.SelectError}
          defaultValue={-1}
        >
          <option value={-1} disabled>
            {t('operation.shareholderChoice')}
          </option>
          {
            (operation.id && !operation.isNew)
              ? (
                shareholders
                  .filter((shareholder) => history.history
                    .filter((h) => h.name === operation.name)[0]
                    ?.shareholders
                    .includes(shareholder.id) || shareholder.shares === 0)
                  .map((shareholder) => (
                    <option
                      key={shareholder.id}
                      value={shareholder.id}
                    >
                      {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.mail}
                    </option>
                  ))
              )
              : (
                shareholders
                  .filter((shareholder) => (history.history[0]
                    ?.shareholders
                    .includes(shareholder.id)) || shareholder.shares === 0)
                  .map((shareholder) => (
                    <option
                      key={shareholder.id}
                      value={shareholder.id}
                    >
                      {(shareholder.firstName) ? `${shareholder.firstName} ${shareholder.lastName}` : shareholder.mail}
                    </option>
                  ))
              )
          }
        </select>
        {
          (errors.to)
          && (
            <p className={`${styles.label} ${styles.error}`}>
              {errors.to}
            </p>
          )
        }
        <Button
          label={t('operation.shareholderCreation')}
          variant="ghost"
          onButtonClick={openForm}
          className={styles.creationButton}
        />
      </div>
      <InputText
        label={t('operation.transferTitle')}
        type="number"
        name="quantity"
        valueInput={values.quantity || ''}
        onTextChange={handleChange}
        state={errors.quantity && statuses.FAIL}
        hint={errors.quantity}
      />
      <button
        type="button"
        aria-label="clear"
        onClick={() => deleteOperation(operation.id)}
      >
        <ClearIcon />
      </button>
    </div>
  );
}
