import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

import * as statuses from '../../services/store/states';
import ImgPlaceholder from '../ImgPlaceholder';
import styles from './Society.module.css';

export default function SocietyInfo({
  status, name, owner, social, logoUrl,
}) {
  const { t } = useTranslation();

  const fallbackLogo = () => (
    <div className={`${styles.image} ${styles.default}`}>
      <p>{name}</p>
    </div>
  );

  if (status === statuses.LOADING) {
    return (<div className={`${styles.infoContainer} ${styles.infoContainerPlaceholder}`} />);
  }

  return (
    <div className={`fadeIn ${styles.infoContainer}`}>
      <div className={styles.infoContent}>
        <div className={styles.imgContainer}>
          {
            (logoUrl) && (
              <ImgPlaceholder
                src={logoUrl}
                alt={`${t('society.info.logo')} ${name}`}
                className={styles.image}
              >
                {fallbackLogo()}
              </ImgPlaceholder>
            )
          }
          {
            !logoUrl && fallbackLogo()
          }
        </div>
        <div className={styles.infoDescription}>
          <h1>
            {name}
          </h1>
          <p>
            {`${t('society.info.by')} ${owner}`}
          </p>
          <div className={styles.socialsList}>
            {
              (social?.facebook) && (
                <a
                  href={social?.facebook}
                  aria-label="facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faFacebook}
                  />
                </a>
              )
            }
            {
              (social?.twitter) && (
                <a
                  href={social?.twitter}
                  aria-label="twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faTwitter}
                  />
                </a>
              )
            }
            {
              (social?.linkedIn) && (
                <a
                  href={social?.linkedIn}
                  aria-label="linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faLinkedinIn}
                  />
                </a>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
